// assets
import { IconLayout, IconHome } from '@tabler/icons';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import UpgradeIcon from '@mui/icons-material/Upgrade';
// constant
const icons = { IconLayout, IconHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const purchase = {
    id: 'purchase',
    title: 'Purchase Package',
    type: 'group',
    // caption: 'Stay Informed and Track Your Progress!',
    children: [
        {
            id: 'packages',
            title: 'Buy a package',
            type: 'item',
            url: '/packages',
            icon: LocalMallOutlinedIcon,
            breadcrumbs: true
        }
        // {
        //     id: 'upgrade package',
        //     title: 'Upgrade package',
        //     type: 'item',
        //     url: '/upgrade',
        //     icon: UpgradeIcon,
        //     breadcrumbs: true
        // }
    ]
};

export default purchase;
