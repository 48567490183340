import { useNavigate, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({}) => {
    const theme = useTheme();

    const navigate = useNavigate();

    const location = useLocation();
    const { pathname } = location;

    const goBack = () => {
        navigate('/dashboard');
    };

    return (
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                {pathname !== '/demographics' && (
                    <Button onClick={goBack} startIcon={<ArrowBack />}>
                        Back to Dashboard
                    </Button>
                )}
            </Grid>

            <Grid item>
                <Box
                    sx={{
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                        <LogoSection />
                    </Box>
                </Box>
            </Grid>

            {/* profile */}
            {/* <Grid item>
                <ProfileSection />
            </Grid> */}
        </Grid>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
