import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import BarChart from 'ui-component/Charts/Bar';
import { useSelector } from 'react-redux';

export default function Details({ detailsList }) {
    const borderRadius = useSelector((state) => state.customization.borderRadius);

    return (
        <Grid
            sx={{
                borderRadius: `${borderRadius}px`,
                background: 'white',
                p: 2
            }}
        >
            <BarChart isPercent custom={true} indexAxis="y" height={'500px'} displayLegend={false} data={detailsList} />
        </Grid>
    );
}
