// assets
import { IconLayout, IconHome } from '@tabler/icons';
import ChecklistIcon from '@mui/icons-material/Checklist';
// constant
const icons = { IconLayout, IconHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const myreports = {
    id: 'myreports',
    title: 'My Reports',
    type: 'group',
    // caption: 'Stay Informed and Track Your Progress!',
    children: [
        {
            id: 'View All My Reports',
            title: 'View All My Reports',
            type: 'item',
            url: '/done_assessments',
            icon: ChecklistIcon,
            breadcrumbs: true
        }
    ]
};

export default myreports;
