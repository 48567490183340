import { lazy } from 'react';

// project imports
import MinimalHeaderLayout from 'layout/MinimalHeaderLayout';
import Loadable from 'ui-component/Loadable';

import PrivateRoute from './PrivateRoutes';
// dashboard routing
import { useLocation } from 'react-router-dom';

const Packages = Loadable(lazy(() => import('views/packages/index')));
const Themes = Loadable(lazy(() => import('views/themes')));
const Question = Loadable(lazy(() => import('views/Question')));
const FeedBack = Loadable(lazy(() => import('views/feedback')));
const Demographics = Loadable(lazy(() => import('views/demographics')));
const Purchase = Loadable(lazy(() => import('views/Purchase')));
const InvoiceResponse = Loadable(lazy(() => import('views/invoice_response')));
const Invoice_Upgrade = Loadable(lazy(() => import('views/invoice_upgrade')));
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MinimalHeaderLayout />,
    children: [
        {
            path: '/packages',
            element: (
                <PrivateRoute>
                    <Packages />
                </PrivateRoute>
            )
        },
        {
            path: '/assessments/theme/:themeid',
            element: (
                <PrivateRoute>
                    <Themes />
                </PrivateRoute>
            )
        },
        {
            path: 'invoice_response/:status',
            element: (
                <PrivateRoute>
                    <InvoiceResponse />
                </PrivateRoute>
            )
        },
        {
            path: 'invoice_response/:status/:upgrad_state',
            element: (
                <PrivateRoute>
                    <Invoice_Upgrade />
                </PrivateRoute>
            )
        },

        {
            path: '/assessments/:themeid/:theme/question/:id',
            element: (
                <PrivateRoute>
                    <Question />
                </PrivateRoute>
            )
        },
        {
            path: '/feedback/:themeid',
            element: (
                <PrivateRoute>
                    <FeedBack />
                </PrivateRoute>
            )
        },
        {
            path: '/demographics',
            element: (
                <PrivateRoute>
                    <Demographics />
                </PrivateRoute>
            )
        },
        {
            path: '/purchase',
            element: (
                <PrivateRoute>
                    <Purchase />
                </PrivateRoute>
            )
        }
    ]
};

export default MainRoutes;
