import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, useMediaQuery, Grid } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, drawerOpened }) => {
    const theme = useTheme();
    const matchs = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <>
            {/* logo & toggler button */}
            <Grid
                container
                sx={{
                    display: 'flex',
                    position: 'relative'
                }}
            >
                {matchs && (
                    <Grid mr={10}>
                        <LogoSection />
                    </Grid>
                )}

                <Grid zIndex={100} item xs={1}>
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', width: '5rem' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.primary.light,
                                color: theme.palette.secondary.main,
                                '&:hover': {
                                    background: theme.palette.secondary.main,
                                    color: theme.palette.primary.light
                                },
                                fontSize: '0.8rem',
                                fontWeight: '600',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                marginBottom: 0
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            {/* <IconMenu2 stroke={1.5} size="1.3rem" /> */}
                            {matchs ? (!drawerOpened ? 'Hide Menu' : 'Show Menu') : drawerOpened ? 'Hide Menu' : 'Show Menu'}
                        </Avatar>
                    </ButtonBase>
                </Grid>
                {!matchs && (
                    <Grid sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100%', left: '0' }}>
                        <LogoSection />
                    </Grid>
                )}
            </Grid>

            {/* space */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* profile */}
            {/* <ProfileSection /> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
