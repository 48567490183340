import React from 'react';

//MUI
import { Grid, CircularProgress } from '@mui/material';

//When Fail data is Showing
import FailData from './Fail';
// 'components/base/FailData/index'

//Render Layout For Loading Progress
export default function LayoutData({
    dataMode,
    children,
    onClickFail,
    messageFail = 'Oops! Something went wrong, please try again later.',
    contentButton = 'Try again',
    showFail = true
}) {
    return (
        <Grid item xs={12}>
            {/* When Data is on Loading  */}
            {dataMode == 'loading' && (
                <Grid height={'100vh'}>
                    <CircularProgress size={30} sx={{ ml: 2 }} />
                </Grid>
            )}

            {/* When Data is Fail to get */}
            {dataMode == 'fail'
                ? showFail && (
                      <Grid>
                          <FailData contentButton={contentButton} onClick={onClickFail} errorMessage={messageFail} />
                      </Grid>
                  )
                : ''}

            {/* When Data is Fetched */}
            {dataMode == 'data' && children}
        </Grid>
    );
}
