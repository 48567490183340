// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items/indvisual';
import menuItemCompany from 'menu-items/companyOwner';
import menuItemCompanyAdmin from 'menu-items/admin';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    let user_detail = useSelector((state) => state.customization.user_detail);

    const navItems = (
        ((user_detail.user_detail?.website_role_name == 'owner' || user_detail.company_account) && menuItemCompany) ||
        (user_detail.user_detail?.website_role_name == 'member' && menuItem) ||
        (user_detail.user_detail?.website_role_name == 'admin' && menuItemCompanyAdmin) ||
        (!user_detail.user_detail?.website_role_name && menuItem)
    ).items?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
