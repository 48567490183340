import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useSelector } from 'react-redux';

export default function AppSelect({ options, value, label, handleChange, defaultValue }) {
    const borderRadius12 = useSelector((state) => state.customization.borderRadius12);
    return (
        <FormControl sx={{ minWidth: '100%' }}>
            <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
            <Select
                style={{ borderRadius: '12px !important' }}
                sx={{
                    width: '100%',
                    '&.Mui-focused fieldset': {
                        borderColor: '#00706b !important'
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: `${borderRadius12}px !important`
                    }
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                onChange={handleChange}
                defaultValue={defaultValue}
            >
                {options?.map((v, i) => {
                    return (
                        <MenuItem key={i} value={v.value} name={v.label} disabled={v.disabled}>
                            {v.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
