import React, { useEffect, useState } from 'react';
import Employedperformance from 'components/employedPerformance';
import MessageCard from 'ui-component/MessageCard';
import { Grid } from '@mui/material';
import api from 'config/api';
import ReportsPack from 'ui-component/ReportPack';
import LayoutData from 'ui-component/LayoutData';
import ImageSource from 'assets/images/messageCard/rep.png';

export default function Performance() {
    const [packages, setPackages] = useState([]);
    const [packageSelected, setPackageSelected] = useState(null);
    const [progres, setProgress] = useState('loading');

    const getPackages = () => {
        setProgress('loading');
        api.getPackageCompany()
            .then((res) => {
                const arr = res.data.map((v, i) => {
                    const theme = v.themes.map((theme, index) => {
                        return { label: theme.theme_title, value: theme.id };
                    });
                    return {
                        ...v,
                        themes: theme
                    };
                });
                setPackages(arr);
                setPackageSelected(arr[0]);
                setProgress('data');
            })
            .catch((err) => {
                console.log(err);
                setProgress('fail');
            });
    };

    const onPackageSelect = (pack) => {
        setPackageSelected(pack);
    };

    useEffect(() => {
        getPackages();
    }, []);
    return (
        <>
            <LayoutData dataMode={progres} onClickFail={() => getPackages()}>
                {packageSelected && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent={packageSelected !== null ? 'flex-start' : 'space-between'}
                            sx={packageSelected !== null && { ml: 5 }}
                        >
                            {packages.map((pack) => (
                                <Grid
                                    container
                                    item
                                    xs={packageSelected ? 2.9 : 12}
                                    md={packageSelected ? 2.9 : 5.9}
                                    sx={{ my: packageSelected !== null ? 0 : 1, transition: 'all 0.5s' }}
                                    key={pack.id}
                                >
                                    <ReportsPack
                                        id={pack.id}
                                        Title={pack.name}
                                        Description={pack.package_desc}
                                        color1={pack.primary_color}
                                        color2={pack.secondary_color}
                                        isClicked={packageSelected !== null}
                                        data={pack}
                                        isSelected={packageSelected && packageSelected.name === pack.name}
                                        handleClick={() => onPackageSelect(pack)}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <MessageCard
                            title={packageSelected.name}
                            subtitle={
                                'In this dashboard you will be informed of your stage of leaderships development, how you performed in each of the assessments on your leadership INTENTION. Furthermore, your overall performance on the questions for each theme (Leadership Character and People Management) are segmented into clusters to provide you insights on your performance on key areas. Finally, we provide an overview of your highest and lowest scores as well as scores on key questions.'
                            }
                            backgroundColor={packageSelected.primary_color}
                            backgroundColor2={packageSelected.secondary_color}
                            imageSrc={ImageSource}
                        />
                    </>
                )}
                <Employedperformance packageSelected={packageSelected} />
            </LayoutData>
        </>
    );
}
