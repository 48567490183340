import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import style from './style.module.scss';
// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items/indvisual';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),

        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const location = useLocation();
    const { pathname } = location;
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const navigate = useNavigate();
    // Handle left drawer
    const drawerOpened = useSelector((state) => state.customization.opened);
    const user_detail = useSelector((state) => state.customization.user_detail);
    const leftDrawerOpened = useMemo(() => drawerOpened, [drawerOpened]);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    const item = localStorage.getItem('paymentExpTime');

    // Check if the item has expired
    if (item && new Date().getTime() > item) {
        // Item has expired, remove it from localStorage
        localStorage.removeItem('paymentExpTime');
        localStorage.removeItem('paymentSuccess');
    }

    useEffect(() => {
        user_detail?.first_name === 'Not Set' &&
            !['/packages', '/purchase', '/demographics'].includes(pathname) &&
            navigate('/demographics');
    }, [user_detail?.first_name]);
    return (
        <Box sx={{ display: 'flex' }}>
            {/* <CssBaseline /> */}
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: 'rgba(255,255,255,.25)',
                    backdropFilter: `blur(${10}px)`,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} drawerOpened={drawerOpened} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={!matchUpMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main
                className={!matchUpMd && leftDrawerOpened ? style.isOpen : style.isClose}
                theme={theme}
                open={leftDrawerOpened}
                sx={{ ml: !matchUpMd ? 0 : 4, overflowX: 'clip' }}
            >
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
        </Box>
    );
};

export default MainLayout;
