// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": `#fff`,
	"menuColor": `#1d3557`,
	"primaryLight": `#f5f5f5`,
	"primary200": `#b39ddb`,
	"primaryMain": `#00706b`,
	"primaryDark": `#008a83`,
	"primary800": `#464775`,
	"secondaryLight": `#fffdf8`,
	"secondary200": `#b39ddb`,
	"secondaryMain": `#007e8f`,
	"secondaryDark": `#84e9e2`,
	"secondary800": `#7bc7c2`,
	"secondary900": `#ffae85`,
	"secondary1000": `#fff0e8`,
	"secondary1100": `#ff672a`,
	"successLight": `#b9f6ca`,
	"success200": `#69f0ae`,
	"successMain": `#00e676`,
	"successDark": `#00c853`,
	"errorLight": `#ef9a9a`,
	"errorMain": `#f44336`,
	"errorDark": `#c62828`,
	"orangeLight": `#fbe9e7`,
	"orangeMain": `#ffab91`,
	"orangeDark": `#d84315`,
	"warningLight": `#fff0e8`,
	"warningMain": `#ffae85`,
	"warningDark": `#ff672a`,
	"grey50": `#fafafa`,
	"grey100": `#f5f5f5`,
	"grey200": `#eeeeee`,
	"grey300": `#cdcecb`,
	"grey500": `#9e9e9e`,
	"grey600": `#757575`,
	"grey700": `#616161`,
	"grey800": `#e7e7e7`,
	"grey900": `#212121`,
	"onPrimary": `#fffdf8`,
	"selectedColor": `rgba(96, 215, 207, 0.4)`,
	"body1": `#141414`,
	"surface": `#284b63`,
	"onsurface": `#fffdf8`
};
export default ___CSS_LOADER_EXPORT___;
