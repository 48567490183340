import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';

export default function AppTab({ tabs, sxContent, onChangeTab, defaultValue }) {
    //tabs props most have array of Object like=[{title:"any",value:"any",content:HTMLElement}]
    const [value, setValue] = useState(defaultValue ? defaultValue : '1');
    const handleChange = (e, v) => {
        setValue(v);
        onChangeTab(e, v);
    };
    return (
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                    sx={{
                        '& .MuiTabs-indicator': { background: '#007E8F' },
                        '& button.Mui-selected': { color: '#141414' },
                        maxWidth: { xs: 320, sm: 500 }
                    }}
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                >
                    {tabs.map((v, i) => {
                        return <Tab sx={{ textTransform: 'none' }} label={v.title} value={v.value} key={i} />;
                    })}
                </TabList>
            </Box>
            {tabs.map((v, i) => {
                return (
                    <TabPanel key={i} value={v.value} sx={sxContent}>
                        {v.content}
                    </TabPanel>
                );
            })}
        </TabContext>
    );
}
