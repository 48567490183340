// assets
import FeedbackIcon from '@mui/icons-material/Feedback';
// constant
const icons = {
    FeedbackIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'offer Feedback',
    title: 'Offer Feedback',
    // caption: 'Access Your Results and Analyze Your Performance',
    type: 'group',
    children: [
        {
            id: 'feedback',
            title: 'Feedback',
            type: 'item',
            url: '/feedback',
            icon: icons.FeedbackIcon,
            breadcrumbs: true
        }
    ]
};

export default pages;
