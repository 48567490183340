import React from 'react';
import { Box, Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import Doughnut from 'ui-component/Charts/Doughnut';

// Function to determine if a color is bright or dark
export const isColorBright = (color) => {
    const rgb = parseInt(color.substring(1), 16); // Convert to RGB
    const r = (rgb >> 16) & 0xff; // Extract red
    const g = (rgb >> 8) & 0xff; // Extract green
    const b = (rgb >> 0) & 0xff; // Extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Per ITU-R BT.709

    return luma > 127;
};

// ==============================|| SAMPLE PAGE ||============================== //

const Reports = ({ color1, color2, Title, Score, Description, isClicked, isSelected, handleClick, data }) => {
    const theme = useTheme();
    const borderRadius = useSelector((state) => state.customization.borderRadius);

    // Create gradient background from given colors
    const gradientBackground = `linear-gradient(352deg, ${color2} 0%, ${color1} 100%)`;
    const textColor = isColorBright(color1) && isColorBright(color2) ? 'black' : 'white'; // Change text color based on brightness

    return (
        <Box
            sx={{
                display: 'flex',
                position: 'relative',
                overflow: 'hidden',

                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingX: isClicked ? 1 : 2,
                paddingY: isClicked ? 2 : 4,
                width: '100%',
                zIndex: isSelected ? 100 : 0,
                borderRadius: isClicked ? `${borderRadius}px ${borderRadius}px 0px 0px` : `${borderRadius}px`,
                background: `${gradientBackground}`,
                backgroundSize: '200% 200%',
                color: `${textColor}`, // Apply text color
                // border: isSelected ? `2px solid ${color2}` : 'none', // Highlight the selected report
                transform: isSelected ? 'scale(1.15)' : 'scale(1)',
                '&:hover': {
                    transform: 'scale(1.02)',
                    animation: 'spin 3s linear infinite',
                    backgroundPosition: 'right bottom'
                },
                transition: 'transform 0.3s ease-in-out, width 0.3s ease-in-out, border 0.5s, background-position 0.5s, animation 0.5s',
                cursor: 'pointer'
            }}
            onClick={handleClick}
        >
            {isClicked ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                    <Typography variant={'h4'} color={textColor}>
                        {Title}
                    </Typography>
                </Grid>
            ) : (
                <Grid container alignItems="center" spacing={2} sx={{ zIndex: 0, position: 'relative', pl: 2 }}>
                    {/* TEXT AREA */}
                    <Grid item xs={12} md={12} sx={{ pr: 2 }}>
                        <Typography variant="h4" gutterBottom color={textColor}>
                            {Title}
                        </Typography>
                        <Typography variant="body2" color={textColor}>
                            {Description}
                        </Typography>
                    </Grid>

                    {/* IMAGE */}
                    {/* <Grid item xs={12} md={4} sx={{ zIndex: 2 }}>
                        <Doughnut title={data.product} data={data ? data : []} color1={data.primary_color} color2={data.secondary_color} />
                    </Grid> */}
                </Grid>
            )}
        </Box>
    );
};

export default Reports;
