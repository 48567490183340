import React from 'react';
import { Button, Box } from '@mui/material';
import style from './style.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
export default function Appbutton({
    children,
    onClick,
    type = 'submit',
    loading,
    sx,
    variant = 'contained',
    color = 'primary',
    fullWidth,
    disabled,
    boxmt = 2
}) {
    return (
        <Box mt={boxmt}>
            <Button
                disabled={disabled}
                fullWidth={fullWidth}
                variant={variant}
                color={color}
                style={{ textTransform: 'none' }}
                sx={{ ...sx }}
                onClick={onClick}
                type={type}
            >
                {children}
                {loading && <CircularProgress className={style.loading} size={26} />}
            </Button>
        </Box>
    );
}
