import config from './global';

export default {
    login: (body) => {
        return config.axiosHandler().post('login', body);
    },
    directLogin: (body) => {
        return config.axiosHandler().post('direct-login', body);
    },
    forgetPassword: (body) => {
        return config.axiosHandler().post('user/forget_password', body);
    },
    getPackages: () => {
        return config.axiosHandler().get('products');
    },
    Register: (body) => {
        return config.axiosHandler().post('register', body);
    },
    Verify: (body) => {
        return config.axiosHandler().post(`user/verify`, body);
    },
    VerifyCode: (body) => {
        return config.axiosHandler().post(`user/verify/resend`, body);
    },
    resetPassword: (body) => {
        return config.axiosHandler().post(`/user/reset_password`, body);
    },
    changePassword: (body) => {
        return config.axiosHandler().post(`/user/change_password`, body);
    },
    registerPendingUsers: (body, token) => {
        return config.axiosHandler().post(`invited_users/${token}`, body);
    },
    test: (body) => {
        return config.axiosHandler().post('usersubscription', body);
    },
    getUser: () => {
        return config.axiosHandler().get('getuser');
    },
    UpdateUser: (body) => {
        return config.axiosHandler().put('/users/update', body);
    },
    createUserDetails: (body, method = 'post') => {
        return config.axiosHandler()[method]('/users/details', body);
    },
    createCompanyDetails: (body) => {
        return config.axiosHandler()['post']('/companydetails', body);
    },
    createCompanyDetailsUpdate: (body) => {
        return config.axiosHandler().put('/companydetails', body);
    },
    inserCompanyInfo: (body) => {
        return config.axiosHandler()['put']('/users/companyinfo', body);
    },
    createInfoDetails: (body, method = 'post') => {
        return config.axiosHandler()[method]('/users/addinfo', body);
    },
    checkUserDetail: () => {
        return config.axiosHandler().get('/users/details');
    },

    getAdditionalThemes: () => {
        return config.axiosHandler().get('/themes/additional');
    },
    getThemeProduct: () => {
        return config.axiosHandler().get('/products/1/themes');
    },
    userSubscription: (body) => {
        return config.axiosHandler().get('/usersubscription');
    },
    userPurchaseSubscription: (body) => {
        return config.axiosHandler().post(`/usersubscription`, body);
    },
    getAttempts: (id) => {
        return config.axiosHandler().get(`/getattempts`);
    },
    getReportAttempts: (id) => {
        return config.axiosHandler().get(`report/attempts`);
    },
    upcomingAssessments: (isDashboard) => {
        return config.axiosHandler().get(`/upcoming_assessments${isDashboard ? '?dashboard=true&limit=4' : ''}`);
    },
    pastAssessments: () => {
        return config.axiosHandler().get(`/past_assessments`);
    },
    assessmentList: (id) => {
        return config.axiosHandler().get(`/assessments/${id}`);
    },
    insertThemeQuestions: (id, body) => {
        return config.axiosHandler().post(`/questionresult/${id}`, body);
    },
    reportList: () => {
        return config.axiosHandler().get('get_done_attempts');
    },
    ReportAnalysiskeyLeaderShip: (id) => {
        return config.axiosHandler().get(`keyleadership/${id}`);
    },
    themes: (id) => {
        return config.axiosHandler().get(`keyleadership/${id}/additional`);
    },
    reportAnalysisquestionScore: (id, themeId) => {
        return config.axiosHandler().get(`keyleadership/${id}/question_glance/${themeId}`);
    },
    reportAnalysisInsight: (id, themeId) => {
        return config.axiosHandler().get(`keyleadership/${id}/insight/${themeId}`);
    },
    reportOverall_keyLeader: () => {
        return config.axiosHandler().get('keyleadership');
    },
    reportOverall_additional_theme: () => {
        return config.axiosHandler().get('keyleadership/additional');
    },
    reportOverall_theme_overview: (id) => {
        return config.axiosHandler().get(`keyleadership/additional/${id}`);
    },
    reportOverall_theme_overview_question: (id) => {
        return config.axiosHandler().get(`overview/question_score`);
    },
    reportOverall_theme_questionGlance: (id) => {
        return config.axiosHandler().get(`keyleadership/additional/questions/${id}`);
    },
    userFeedBack: (body, id) => {
        return config.axiosHandler().post(`user_feedback`, body);
    },
    overviewScores: () => {
        return config.axiosHandler().get(`keyleadership`);
    },
    overviewTopThree: () => {
        return config.axiosHandler().get(`overview/top_three`);
    },
    overview_packageData: () => {
        return config.axiosHandler().get(`overview/subscription`);
    },
    overview_insights: (id) => {
        return config.axiosHandler().get(`keyleadership/insights/${id}`);
    },
    company_info: () => {
        return config.axiosHandler().get(`users/companyinfo`);
    },
    edit_company_info: (body) => {
        return config.axiosHandler().put(`users/companyinfo`, body);
    },
    report_overall_leadership: () => {
        return config.axiosHandler().get(`keyleadership/reportnarrative`);
    },
    overall_questionGlance_data: (id) => {
        return config.axiosHandler().get(`keyleadership/additional/questions/${id}`);
    },

    EmployedPerformanceAttempts: () => {
        return config.axiosHandler().get('/company/attempts');
    },
    EP_detail: (id) => {
        return config.axiosHandler().get(`/company/members_performance/${id}`);
    },
    EP_overall: () => {
        return config.axiosHandler().get('/company/members_performance');
    },
    EP_Grapically_themes: () => {
        return config.axiosHandler().get('/company/members_performance/graphically/themes');
    },
    EP_Grapically_overall: (id) => {
        return config.axiosHandler().get(`/company/members_performance/graphically/overall/${id}`);
    },
    EP_Grapically_detail: (themeId, attId) => {
        return config.axiosHandler().get(`company/members_performance/graphically/${themeId}/details/${attId}`);
    },
    EM_deactive: () => {
        return config.axiosHandler().get(`company/removed_members`);
    },

    company_user_remove: (id) => {
        return config.axiosHandler().put(`company/changerole`);
    },
    package_info: () => {
        return config.axiosHandler().get(`packageinfo`);
    },

    getKeyleadershipNarrativeOverview: () => {
        return config.axiosHandler().get(`/keyleadership/reportnarrative`);
    },

    getAllQuestions: (id) => {
        return config.axiosHandler().post(`/themes/questions`, { themeId: id });
    },
    progressSummery: (id) => {
        return config.axiosHandler().get(`/progress_summary${id ? `/${id}` : ''}`);
    },
    //---------------------PDF API--------------------//
    getinsightsDataPDF: (body) => {
        return config.axiosHandler().post('pdf_result/theme_insights', body);
    },
    getQuestions_result: (body) => {
        return config.axiosHandler().post('pdf_result/questions_interpretation', body);
    },
    getRecommendationPDF: (body) => {
        return config.axiosHandler().post('pdf_result/recommendations', body);
    },
    leadership_level: (body) => {
        return config.axiosHandler().post('report/leadership', body);
    },

    //
    questionDetails: (id) => {
        return config.axiosHandler().get(`/pdf_result/${id}/question_details`);
    },
    PDF_userdata: (id) => {
        return config.axiosHandler().get(`pdf_result/${id}/user_data`);
    },
    PDF_narrative: (id) => {
        return config.axiosHandler().get(`pdf_result/${id}/report_narrative`);
    },

    PDF_theme: (id) => {
        return config.axiosHandler().get(`pdf_result/${id}/theme_results`);
    },
    PDF_summary: (id) => {
        return config.axiosHandler().get(`/pdf_result/${id}/theme_summary`);
    },
    PDF_package_info: (id) => {
        return config.axiosHandler().get(`pdf_result/${id}/packageinfo`);
    },
    //
    summaryAttempts: (body) => {
        return config.axiosHandler().post(`/overview/questions_summary`, body);
    },
    overViewLeadership: (id) => {
        return config.axiosHandler().get(`/overview/leadership/${id}`);
    },
    overViewInsight: (body) => {
        return config.axiosHandler().post(`/overview/insights`, body);
    },
    //----------------------PDF end---------------//

    getAchiv: () => {
        return config.axiosHandler().get(`/achievements`);
    },

    // ------------------REport Page API------------//

    getSummaryReport: (id) => {
        return config.axiosHandler().get(`/report/summary/${id}`);
    },
    getAchievmentReport: (id) => {
        return config.axiosHandler().get(`/report/achievement/${id}`);
    },
    getleaderShip: (body) => {
        return config.axiosHandler().post(`/report/leadership`, body);
    },
    getAttempt: (id) => {
        return config.axiosHandler().get(`/usersubscription/${id}`);
    },
    getInterception: (id) => {
        return config.axiosHandler().post(`/report/interception/`, id);
    },
    getlatestScores: (body) => {
        return config.axiosHandler().post(`report/theme_results`, body);
    },
    getQuestionGlance: (body) => {
        return config.axiosHandler().post(`/report/question_glance`, body);
    },
    getInsight: (body) => {
        return config.axiosHandler().post(`/report/insights`, body);
    },
    getTopAndWorst: (body) => {
        return config.axiosHandler().post(`/report/worst_and_best`, body);
    },
    getTopFive: (body) => {
        return config.axiosHandler().post(`/report/top_five`, body);
    },
    getInterpration: (body) => {
        return config.axiosHandler().post(`/report/interception/`, body);
    },
    getRecommendation: (body) => {
        return config.axiosHandler().post(`/report/recommendations`, body);
    },
    //--------------REPORT page end ----------------------//

    //---------------------------OVerView API PAge--------------------//
    getLeaderShip: (id) => {
        return config.axiosHandler().get(`/overview/leadership/${id}`);
    },
    getLineChartProgressOver: (id) => {
        return config.axiosHandler().get(`/overview/linechart/${id}`);
    },
    getInterceptionOverview: (id) => {
        return config.axiosHandler().get(`/overview/interpretation`);
    },
    getTopFiveOverview: (id) => {
        return config.axiosHandler().get(`/overview/top_five/${id}`);
    },
    // yourLatestScore: () => {
    //     return config.axiosHandler().get(`/overview/latest_score`);
    // },
    progressTimeThemes: () => {
        return config.axiosHandler().get(`/overview/progress_themes`);
    },
    getPeersData: (id) => {
        return config.axiosHandler().get(`/overview/question_peers/${id}`);
    },
    //---------------------------OVerView API PAge END------------//

    progressAssessment: () => {
        return config.axiosHandler().get(`/side_progress`);
    },

    //PackageINFO
    currentPackages: () => {
        return config.axiosHandler().get(`/packages/active`);
    },
    expirePackages: () => {
        return config.axiosHandler().get(`/packages/expired`);
    },

    //Remove Account
    removeAccount: () => {
        return config.axiosHandler().delete(`/users`);
    },
    //----------------- COMPANY API START ---------------------

    // ========== Employed managment start========
    getPackageCompany: () => {
        return config.axiosHandler().get(`/companies/packages`);
    },
    addMember: (body) => {
        return config.axiosHandler().post('/companies/invitation', body);
    },
    getMember: (page, size) => {
        return config.axiosHandler().get(`companies/members?page=${page}&size=${size}`);
    },
    PendingUsersGetEmail: (token) => {
        return config.axiosHandler().get(`/invited_users/${token}`);
    },
    PendingUsersRegister: (body) => {
        return config.axiosHandler().post(`/invited_users`, body);
    },
    removePendingMember: (id) => {
        return config.axiosHandler().delete(`companies/invite_cancel/${id}`);
    },
    company_change_rol: (body) => {
        return config.axiosHandler().put(`companies/changerole`, body);
    },
    removeCompanyMember: (id) => {
        return config.axiosHandler().post('/companies/removemember', { memberId: id });
    },
    resendEmail: (id) => {
        return config.axiosHandler().post(`companies/resend/${id}`);
    },
    assignSinglePackages: (body) => {
        return config.axiosHandler().post(`/companies/packages/single`, body);
    },
    assignMultiPackages: (body) => {
        return config.axiosHandler().post(`/companies/packages/multi`, body);
    },
    companyPackages: () => {
        return config.axiosHandler().get(`/companies/packages/active`);
    },
    //   -----report user of company-----//

    packagesUserCompany: (body) => {
        return config.axiosHandler().post(`/companies/members/report/packages`, body);
    },
    //
    getAvrageuser: (body) => {
        return config.axiosHandler().post(`/companies/members/report/overall_average`, body);
    },
    getQuestionAverage: (body) => {
        return config.axiosHandler().post(`/companies/members/report/questions_average`, body);
    },
    topFiveQuestionUserCompany: (body) => {
        return config.axiosHandler().post(`companies/members/report/top_five`, body);
    },

    //
    //   -----report user of company end -----//

    // ================ Employed managment End =========================

    // =========== Employed Performance Start ==============
    getListPerformance: (body) => {
        return config.axiosHandler().post(`/companies/performance/details/numerical`, body);
    },
    getOverallListNumeric: (id) => {
        return config.axiosHandler().get(`/companies/performance/overall/numerical/${id}`);
    },
    getDetailListGraphicaly: (body) => {
        return config.axiosHandler().post(`companies/performance/details/graphically`, body);
    },
    getOverallListGraphicaly: (body) => {
        return config.axiosHandler().post(`/companies/performance/overall/graphically`, body);
    },
    // =========== Employed Performance End ==============

    //----------------- COMPANY API END---------------------

    //==================== PURCHASE PACKAGE =========================

    discount_voucher: (body) => {
        return config.axiosHandler().post('/voucher', body);
    },
    purchasePackageValid: (body) => {
        return config.axiosHandler().post('/subscription/validation', body);
    },
    summaryPurchasePackage: (body) => {
        //
        return config.axiosHandler().post('subscription/summary', body);
    },
    purchasePackage: (body, validate) => {
        return config.axiosHandler(validate).post('/subscription/purchase', body);
    },
    upgrade_package: (body, validate) => {
        return config.axiosHandler(validate).post('/subscription/package_upgrade', body);
    },
    packageTransActionOne: (body, rout) => {
        return config.axiosHandler().post(rout, body);
    },

    sendAsk_qustion: (body) => {
        return config.axiosHandler().post('/query', body);
    },
    getFeedback: () => {
        return config.axiosHandler().get('/users_feedback');
    },
    //==================== Summary Report =========================
    getSummary: (body) => {
        return config.axiosHandler().post('/summary/user', body);
    }
};
