// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_isOpen__bBgHj {
  animation-name: style_is_open__m-B5K;
  animation-duration: 0.2s;
}

.style_isClose__uQtSi {
  animation-name: style_is_close__D4QpT;
  animation-duration: 0.2s;
}

@keyframes style_is_open__m-B5K {
  from {
    width: 0;
    flex: 0 0;
  }
  to {
    width: 260px;
    flex: 0 0 260px;
  }
}
@keyframes style_is_close__D4QpT {
  from {
    width: 260px;
    flex: 0 0 260px;
  }
  to {
    width: 0;
    flex: 0 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/MainLayout/style.module.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,wBAAA;AACJ;;AAEA;EACI,qCAAA;EACA,wBAAA;AACJ;;AAEA;EACI;IACI,QAAA;IACA,SAAA;EACN;EACE;IACI,YAAA;IACA,eAAA;EACN;AACF;AACA;EACI;IACI,YAAA;IACA,eAAA;EACN;EACE;IACI,QAAA;IACA,SAAA;EACN;AACF","sourcesContent":[".isOpen {\n    animation-name: is_open;\n    animation-duration: 0.2s;\n}\n\n.isClose {\n    animation-name: is_close;\n    animation-duration: 0.2s;\n}\n\n@keyframes is_open {\n    from {\n        width: 0;\n        flex: 0 0 0;\n    }\n    to {\n        width: 260px;\n        flex: 0 0 260px;\n    }\n}\n@keyframes is_close {\n    from {\n        width: 260px;\n        flex: 0 0 260px;\n    }\n    to {\n        width: 0;\n        flex: 0 0 0px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"isOpen": `style_isOpen__bBgHj`,
	"is_open": `style_is_open__m-B5K`,
	"isClose": `style_isClose__uQtSi`,
	"is_close": `style_is_close__D4QpT`
};
export default ___CSS_LOADER_EXPORT___;
