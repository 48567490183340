// assets
import { IconLayout, IconHome } from '@tabler/icons';

// constant
const icons = { IconLayout, IconHome };
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const assessment = {
    id: 'take assessment',
    title: 'Take Assessment',
    type: 'group',
    // caption: 'Stay Informed and Track Your Progress!',
    children: [
        {
            id: 'Upcoming Assessments',
            title: 'Upcoming Assessments',
            type: 'item',
            url: '/assessments',
            icon: FeaturedPlayListOutlinedIcon,
            breadcrumbs: true
        }
        // {
        //     id: 'Completed Assessments',
        //     title: 'Completed Assessments',
        //     type: 'item',
        //     url: '/done_assessments',
        //     icon: ChecklistIcon,
        //     breadcrumbs: true
        // }
    ]
};

export default assessment;
