import { Grid, Typography, Button } from '@mui/material';
import React from 'react';

export default function FailData({ contentButton, errorMessage, onClick, loading }) {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid display={'flex'} alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h5">{errorMessage}</Typography>
            </Grid>

            <Grid>
                <Button loading={loading} onClick={onClick} variant="contained">
                    {contentButton}
                </Button>
            </Grid>
        </Grid>
    );
}
