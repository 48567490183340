// assets
import { IconLayout, IconHome } from '@tabler/icons';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PermDataSettingOutlinedIcon from '@mui/icons-material/PermDataSettingOutlined';
// constant
const icons = { IconLayout, IconHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const company = {
    id: 'company',
    title: 'My Company',
    type: 'group',
    caption: 'Stay Informed and Track Your Progress!',
    children: [
        {
            id: 'Employees Management',
            title: 'Employees Management',
            type: 'item',
            url: '/managment',
            icon: GroupsOutlinedIcon,
            breadcrumbs: true
        },
        {
            id: 'Employees Performance',
            title: 'Employees Performance',
            type: 'item',
            url: '/performance',
            icon: PermDataSettingOutlinedIcon,
            breadcrumbs: true
        }
        // {
        //     id: 'Company Details',
        //     title: 'Company Details',
        //     type: 'item',
        //     url: '/companydetail',
        //     icon: icons.IconLayout,
        //     breadcrumbs: true
        // }
    ]
};

export default company;
