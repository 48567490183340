import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const NotFoundPage = Loadable(lazy(() => import('views/404')));
const Notfound = {
    path: '',
    children: [
        {
            path: '*',
            element: <NotFoundPage />
        }
    ]
};

export default Notfound;
