// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_box_modal__p3AEs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 32px;
  border-radius: 20px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.style_box_modal_confrim__PD7vH {
  position: absolute;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  padding: 32px;
  border-radius: 20px;
}

.style_modal_close__zXdiQ {
  margin-left: auto;
}

.style_modal_button__XZU-O {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;
}

.style_cancel_btn__A8-\\+5 {
  background-color: white;
  border: 1px solid #60d7cf;
}`, "",{"version":3,"sources":["webpack://./src/common/AppModal/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EAGA,uLAAA;AADJ;;AAIA;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,UAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AADJ;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;;AAEA;EACI,uBAAA;EACA,yBAAA;AACJ","sourcesContent":[".box_modal {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: white;\n    padding: 32px;\n    border-radius: 20px;\n    overflow-y: auto;\n    // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;\n    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,\n        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;\n}\n.box_modal_confrim {\n    position: absolute;\n    top: 50%;\n    left: 54%;\n    transform: translate(-50%, -50%);\n    width: 70%;\n    background-color: white;\n    padding: 32px;\n    border-radius: 20px;\n}\n.modal_close {\n    margin-left: auto;\n    // margin-top: 50px;\n}\n.modal_button {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    margin-top: 50px;\n}\n.cancel_btn {\n    background-color: white;\n    border: 1px solid #60d7cf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box_modal": `style_box_modal__p3AEs`,
	"box_modal_confrim": `style_box_modal_confrim__PD7vH`,
	"modal_close": `style_modal_close__zXdiQ`,
	"modal_button": `style_modal_button__XZU-O`,
	"cancel_btn": `style_cancel_btn__A8-+5`
};
export default ___CSS_LOADER_EXPORT___;
