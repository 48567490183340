import React, { useEffect } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { useSelector } from 'react-redux';

export default function AppAccording({
    data,
    color,
    classNameContainer,
    sx,
    aroundContentBg = 'white',
    background,
    accordionDetailsBg = 'white',
    defaultExpandedPanel = false,
    arrowIconStyles = {
        color: '#212121',
        transform: 'rotate(90deg)',
        marginRight: '12px'
    },
    flexDirectonOfArrowAndTitle = 'row-reverse',
    heightOfSummary
}) {
    const borderRadius = useSelector((state) => state.customization.borderRadius);
    const [expanded, setExpanded] = React.useState(defaultExpandedPanel);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            {data.map((v, i) => {
                return (
                    <Accordion
                        expanded={expanded === `panel${i}`}
                        onChange={handleChange(`panel${i}`)}
                        key={i}
                        sx={{
                            '&:before': {
                                display: 'none'
                            },
                            backgroundColor: color,
                            background: aroundContentBg,
                            boxShadow: 'none',
                            mb: 5
                        }}
                        className={classNameContainer}
                        style={{ borderRadius: `${borderRadius}px`, margin: 0 }}
                    >
                        <AccordionSummary
                            sx={{
                                height: heightOfSummary,
                                margin: 0,
                                borderRadius: `${borderRadius}px`,
                                // transformOrigin:"center center",
                                background: background,
                                flexDirection: flexDirectonOfArrowAndTitle,
                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                    transform: 'rotate(-180deg)',
                                    marginTop: '5px',
                                    marginLeft: '-12px',
                                    marginRight: '12px'
                                },
                                '& .MuiAccordionSummary-content.Mui-expanded': {
                                    margin: 0
                                }
                            }}
                            expandIcon={<ArrowForwardIosSharpIcon sx={arrowIconStyles} fontSize="small" />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            {v.title}
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                marginTop: '20px',
                                backgroundColor: accordionDetailsBg,
                                padding: '0'
                            }}
                        >
                            {v.content}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
}
