import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery, Grid, Button } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import navigation from 'menu-items/indvisual';

// assets
import { IconChevronRight } from '@tabler/icons';
import CircleDotted from 'assets/images/DottCircle.png';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContentHeader,
    position: 'relative',
    ...(!open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            width: `calc(100%)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100%)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100%)`,
            padding: '16px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        width: `calc(100%)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));

const floatingAnimation = (duration, xOffset, yOffset) => `
  @keyframes floating-${duration}-${xOffset}-${yOffset} {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(${xOffset}px, ${yOffset}px);
    }
    100% {
      transform: translate(0, 0px);
    }
  }
`;

// ==============================|| MAIN LAYOUT ||============================== //

const MainHeaderLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    // Create an array to represent multiple instances of the image
    const circleDots = new Array(4).fill(null);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: 'rgba(255,255,255,.25)',
                    backdropFilter: `blur(${10}px)`
                }}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>

            {/* main content */}
            <Main theme={theme} sx={{ ml: !matchUpMd ? 0 : 2.2, p: 3 }}>
                {/* Map over the array and render each image with random styles */}

                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <div style={{ position: 'relative', zIndex: 2 }}>
                    <Outlet />
                </div>
            </Main>
        </Box>
    );
};

export default MainHeaderLayout;
