// material-ui

import logoLight from 'assets/images/log_dark_mode.png';
import LogoDark from 'assets/images/logo.png';
// ==============================|| LOGO SVG ||============================== //

const Logo = (props) => {
    return props.light ? (
        <img src={logoLight} alt="Verv Logo Light" width={props.size ? `${props.size}px` : '100'} style={props.style} />
    ) : (
        <img src={LogoDark} alt="Verv Logo" width={140} style={props.style} />
    );
};

export default Logo;
