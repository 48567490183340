import React from 'react';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme, List, ListItem } from '@mui/material';
import { useSelector } from 'react-redux';
import Image from 'assets/images/baner_img.png';
// import CircleDotted from "../assets/images/authLayout/circle_dotted.svg";
import CalculateColor from './CalculateFilterBasedOnColor';
import Steps from './Steps';
import { useLocation } from 'react-router';

const steps = {
    ['/dashboard']: [
        {
            key: 1,
            title: 'Step 1',
            value: () => {
                return (
                    <>
                        <Typography color={'black'} fontSize={16}>
                            Purchase a leadership assessment
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 2,
            title: 'Step 2',
            value: () => {
                return (
                    <>
                        <Typography color={'black'} fontSize={16}>
                            TAKE the leadership assessment
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 3,
            title: 'Step 3',
            value: () => {
                return (
                    <Typography color={'black'} fontSize={16}>
                        View your leadership RESULTS
                    </Typography>
                );
            }
        },
        {
            key: 4,
            title: 'Step 4',
            value: () => {
                return (
                    <Typography color={'black'} fontSize={16}>
                        Download your Leadership REPORT
                    </Typography>
                );
            }
        }
    ],
    ['/packages']: [
        {
            key: 11,
            title: 'Step 1',
            value: () => {
                return (
                    <>
                        <Typography variant="span" display={'block'} style={{ fontWeight: 'bolder' }}>
                            Choose who you are buying the Leadership tool for
                        </Typography>
                        <Typography variant="h4" display={'block'} style={{ fontWeight: 'bolder' }}>
                            - Individual, Company or as a Coach
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 12,
            title: 'Step 2',
            value: () => {
                return (
                    <>
                        {' '}
                        <Typography variant="span" display={'block'} style={{ fontWeight: 'bold', lineHeight: '25px' }}>
                            We recommend you start your leadership journey with the
                            <Typography
                                variant="span"
                                display={'inline'}
                                style={{ fontWeight: 'bolder', fontSize: 18, lineHeight: '25px' }}
                            >
                                {' ' + localStorage.getItem('package_name')}
                            </Typography>
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 13,
            title: 'Step 3',
            value: () => {
                return (
                    <Box>
                        <Typography variant="span" display={'block'} style={{ fontWeight: 'bolder' }}>
                            Select a payment plan:
                        </Typography>
                        <List sx={{ lineHeight: 1 }} style={{ fontWeight: 'bolder' }}>
                            <ListItem>- Basic (no report)</ListItem>
                            <ListItem>- Premium</ListItem>
                            <ListItem>- Growth (12 Attempts)</ListItem>
                        </List>
                    </Box>
                );
            }
        }
    ],
    ['/upgrade']: [
        {
            key: 21,
            title: 'Step 1',
            value: () => {
                return (
                    <>
                        <Typography variant="h6" display={'block'} style={{ fontWeight: 'bolder' }}>
                            Upgrade Package
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 22,
            title: 'Step 2',
            value: () => {
                return (
                    <Box>
                        <Typography variant="h6" display={'block'} style={{ fontWeight: 'bolder' }}>
                            Select Package Type:
                        </Typography>
                        <List sx={{ lineHeight: 1 }} style={{ fontWeight: 'bolder' }}>
                            <ListItem>- Intentions</ListItem>
                            <ListItem>- Inspiration</ListItem>
                            <ListItem>- Impact</ListItem>
                        </List>
                    </Box>
                );
            }
        },
        {
            key: 23,
            title: 'Step 3',
            value: () => {
                return (
                    <Box>
                        <Typography variant="h6" display={'block'}>
                            Select Package deal:
                        </Typography>
                        <List sx={{ lineHeight: 1 }} style={{ fontWeight: 'bolder' }}>
                            <ListItem>- Premium (1 Attempt)</ListItem>
                            <ListItem>- Growth (12 Attempts)</ListItem>
                        </List>
                    </Box>
                );
            }
        }
    ],
    ['/overview']: [
        {
            key: 31,
            title: 'First Result:',
            value: () => {
                return (
                    <>
                        <Typography variant="span" display={'block'} style={{ fontWeight: 'bolder' }}>
                            Summary of
                            <Typography variant="h4" display={'inline'} style={{ fontWeight: 'bolder' }}>
                                {' average score '}
                            </Typography>
                            per theme question
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 32,
            title: 'Second Result:',
            value: () => {
                return (
                    <>
                        <Typography variant="span" display={'block'} style={{ fontWeight: 'bolder' }}>
                            <Typography variant="h4" display={'inline'}>
                                {'Stage of Leadership  '}
                            </Typography>
                            after each Attempt
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 33,
            title: 'Final Result:',
            value: () => {
                return (
                    <>
                        <Typography variant="span" display={'block'} style={{ fontWeight: 'bolder' }}>
                            Average Score for the
                            <Typography variant="h4" display={'inline'}>
                                {' three insights '}
                            </Typography>
                            associated with each theme
                        </Typography>
                    </>
                );
            }
        }
    ],
    ['/done_assessments']: [
        {
            key: 41,
            title: 'Step 1',
            value: () => {
                return (
                    <>
                        <Typography variant="span" display={'block'}>
                            <Typography variant="h4" display={'inline'}>
                                {' Select a Report '}
                            </Typography>
                            by Package Category
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 42,
            title: 'Step 2',
            value: () => {
                return (
                    <>
                        <Typography variant="span" display={'block'}>
                            <Typography variant="h4" display={'inline'}>
                                {' View Report Online '}
                            </Typography>
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 43,
            title: 'Step 3',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {' Download a Report '}
                        </Typography>
                    </>
                );
            }
        }
    ],
    ['/account']: [
        {
            key: 51,
            title: 'Option 1',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Update your Personal Details'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 52,
            title: 'Option 2',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Change Email & Password'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 53,
            title: 'Option 3',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Review Leadership Packages Purchased'}
                        </Typography>
                    </>
                );
            }
        }
    ],
    ['/reports']: [
        {
            key: 61,
            title: 'Initial Result:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Stage of Leadership'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 62,
            title: 'Second Result:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Performance on Questions'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 63,
            title: 'Third Result:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Key Category (insight) Performance'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 64,
            title: 'Fourth Result:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Highest & Lowest Scores'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 65,
            title: 'Finally:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Overall Recommendations'}
                        </Typography>
                    </>
                );
            }
        }
    ],
    ['/assessments']: [
        {
            key: 71,
            title: 'Step 1:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Select ONE Upcoming Assessment'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 72,
            title: 'Step 2:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'Click START Assessment'}
                        </Typography>
                    </>
                );
            }
        },
        {
            key: 73,
            title: 'Step 3:',
            value: () => {
                return (
                    <>
                        <Typography variant="h4" display={'inline'}>
                            {'If Assessment in NOT complete, click CONTINUE'}
                        </Typography>
                    </>
                );
            }
        }
    ]
};

// CustomCard component
function CustomCard({
    title,
    backgroundColor,
    backgroundColor2,
    subtitle,
    buttonText,
    buttonAction,
    imageSrc,
    buttonSecTxt,
    buttonSecend,
    imageHeight = '100%',
    subEmelemnt = false,
    isSteps = false
}) {
    const theme = useTheme();
    const borderRadius = useSelector((state) => state.customization.borderRadius);
    const match = useMediaQuery(theme.breakpoints.up('md'));
    const location = useLocation();
    // Create gradient background from given colors
    const gradientBackground = `linear-gradient(352deg, ${backgroundColor} 0%, ${backgroundColor2} 100%)`;

    // Generate random edge position
    const randomEdgePosition = () => {
        const value = Math.random() * 100;
        return `${value}%`;
    };

    // Generate random size for image
    const randomSize = () => {
        const size = Math.random() * 120 + 50;
        return `${size}px`;
    };

    // Set image opacity
    const opacity = 0.2;

    // Calculate image styles based on side
    const getImageStyles = (side) => {
        const size = randomSize();
        const position = randomEdgePosition();
        let style;

        // Position the image based on the given side
        if (side === 'top' || side === 'bottom') {
            style = {
                top: side === 'top' ? 0 : 'auto',
                bottom: side === 'bottom' ? 0 : 'auto',
                left: position
            };
        } else {
            style = {
                left: side === 'left' ? 0 : 'auto',
                right: side === 'right' ? 0 : 'auto',
                top: position
            };
        }

        return {
            ...style,
            position: 'absolute',
            zIndex: -500,
            width: size,
            height: size,
            opacity: opacity,
            filter: CalculateColor(backgroundColor)
        };
    };
    // Set sides for positioning images
    const sides = ['top', 'right', 'bottom', 'left'];

    return (
        <Box
            sx={{
                zIndex: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: `${borderRadius}px`,
                background: `${gradientBackground}`,
                width: '100%',
                position: 'relative',
                overflow: 'hidden'
            }}
        >
            <Grid container alignItems="flex-start" sx={{ zIndex: 2, position: 'relative' }}>
                {sides.map((side, index) => (
                    <img
                        key={index}
                        src={process.env.PUBLIC_URL + '/images/authLayout/circle_dotted.svg'}
                        alt=""
                        style={getImageStyles(side)}
                    />
                ))}

                {/* TEXT AREA */}
                <Grid item xs={12} sx={{ zIndex: 2 }}>
                    <Grid p={3}>
                        <Typography variant="h2" gutterBottom>
                            {title}
                        </Typography>
                        {isSteps ? (
                            <Steps steps={location.pathname === '/' ? steps['/dashboard'] : steps[location.pathname]}>
                                {subEmelemnt ? subtitle : <Typography variant="subtitle1">{subtitle}</Typography>}
                            </Steps>
                        ) : subEmelemnt ? (
                            subtitle
                        ) : (
                            <Typography variant="subtitle1">{subtitle}</Typography>
                        )}
                        {/* <Grid display={'flex'}>
                            {buttonSecTxt && (
                                <Box mr={1} mt={2}>
                                    <Button variant="outlined" color="primary" onClick={buttonSecend}>
                                        {buttonSecTxt}
                                    </Button>
                                </Box>
                            )}
                            {buttonText && (
                                <Box mt={2}>
                                    <Button variant="contained" color="primary" onClick={buttonAction}>
                                        {buttonText}
                                    </Button>
                                </Box>
                            )}
                        </Grid> */}
                    </Grid>
                </Grid>

                {/* IMAGE */}
                {/* <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{ zIndex: 2, heigh: '100%', position: match ? 'absolute' : 'static', right: 0, bottom: 0, top: 0 }}>
                    <Grid
                        sx={{
                            maskImage: `linear-gradient(to ${!match ? 'top' : 'left'}, rgba(0, 0, 0, 1.0) 50%, transparent 100%)`,
                            // maskImage: '',
                            height: imageHeight
                        }}>
                        <img
                            src={imageSrc}
                            alt=""
                            style={{
                                width: '100%',
                                height: imageHeight,
                                display: 'block',
                                objectFit: 'cover'
                            }}
                        />
                    </Grid>
                </Grid> */}
            </Grid>
        </Box>
    );
}

export default CustomCard;
