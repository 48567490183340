import { Box, Grid } from '@mui/material';
import SwitchContent from 'components/OverView/Packages/SwitchContent';
import React, { useState } from 'react';
import AppSelect from 'common/AppSelect';
import data from '../../../data.json';
import { useEffect } from 'react';
import api from 'config/api';
import AppTab from 'common/AppTab';
import Appbutton from 'common/Appbutton';
import { useSelector } from 'react-redux';
import Overall from './Overall';
import Details from './Details';

export default function Graphically({ packageSelected }) {
    const [detailsList, setDetailsList] = useState({
        labels: [],
        datasets: []
    });

    const [overallList, setOverallList] = useState([]);
    const [loading, setloading] = useState(false);
    const [tabActive, setTabActive] = useState('');

    const [filters, setFilters] = useState({
        gender: '',
        age_category: '',
        themeId: null,
        position: ''
    });
    const [type, setType] = useState('Overall Performance');

    const getDetailPerformance = (body) => {
        setloading(true);
        api.getDetailListGraphicaly(body)
            .then((res) => {
                setDetailsList({
                    labels: res.data.map((v, i) => v.first_name + ' ' + v.last_name),
                    datasets: [
                        {
                            label: 'test',
                            data: res.data.map((v) => Math.floor(v.theme_score)),
                            backgroundColor: packageSelected.primary_color,
                            barPercentage: 0.8
                        }
                    ]
                });
                setloading(false);
            })
            .catch((err) => {
                console.log(err);
                setloading(false);
            });
    };

    const onFilters = (type, e) => {
        setFilters({
            ...filters,
            [type]: e.target.value
        });
    };

    const onSwitchType = (v) => {
        setType(v);
        setThemes((state) => state.map((item, index) => ({ ...item, active: false })));
    };

    const handleFilterDetails = () => {
        type == 'Overall Performance'
            ? getOverally({
                  subscriptionId: packageSelected.id,
                  themeId: filters.themeId ? filters.themeId : packageSelected.themes[0].value,
                  age_category: filters.age_category,
                  gender: filters.gender,
                  position: filters.position
              })
            : getDetailPerformance({
                  ...filters,
                  subscriptionId: packageSelected.id,
                  themeId: filters.themeId ? filters.themeId : packageSelected.themes[0].value
              });
    };

    const getOverally = (body) => {
        setloading(true);
        api.getOverallListGraphicaly(body)
            .then((res) => {
                setOverallList(() =>
                    res.data.map((v, i) => {
                        return {
                            title: v.theme_title,
                            labels: ['Average Score', ...v.insights.map((item) => item.insight_title)],
                            dataset: [
                                {
                                    label: '',
                                    data: [Math.floor(v.avg_score / 10), ...v.insights.map((item) => Math.floor(item.avg_score))],
                                    backgroundColor: packageSelected.primary_color
                                }
                            ]
                        };
                    })
                );
                setloading(false);
            })
            .catch((err) => {
                setloading(false);
                console.log(err);
            });
    };

    useEffect(() => {
        getDetailPerformance({
            subscriptionId: packageSelected.id,
            themeId: packageSelected.themes[0].value,
            age_category: '',
            gender: ''
        });
        getOverally({
            subscriptionId: packageSelected.id,
            themeId: packageSelected.themes[0].value,
            age_category: '',
            gender: '',
            position: ''
        });
    }, []);
    return (
        <>
            <Grid sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                <Grid sx={{ height: '40px', width: '400px' }}>
                    <SwitchContent firstContent="Overall Performance" secendContent="Performance Details" onActiveContent={onSwitchType} />
                </Grid>
            </Grid>

            <Box>
                <Grid container justifyContent={type == 'Overall Performance' ? 'space-between' : 'flex-start'}>
                    {type == 'Overall Performance' ? (
                        <>
                            {/* <Grid item xs={12} sm={5.8} md={2.8} mb={2}>
                                <AppSelect label={'Department'} options={[...data.form1[2].option, { label: 'Empty', value: null }]} />
                            </Grid> */}
                            <Grid item xs={12} sm={5.8} md={2.8} mb={2}>
                                <AppSelect
                                    label={'Position'}
                                    options={[...data.form1[2].option, { label: 'Any', value: null }]}
                                    handleChange={(e) => onFilters('position', e)}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid mr={1} item xs={12} sm={5.8} md={4} mb={2}>
                            <AppSelect
                                label={'Themes'}
                                options={packageSelected.themes}
                                handleChange={(e) => onFilters('themeId', e)}
                                value={filters.themeId ? filters.themeId : packageSelected.themes[0].value}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={5.8} md={2.8} mb={2} mr={1}>
                        <AppSelect
                            label={'Gender'}
                            options={[...data.form1[0].option, { label: 'Any', value: null }]}
                            handleChange={(e) => onFilters('gender', e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5.8} md={2.8} mb={2} mr={1}>
                        <AppSelect
                            handleChange={(e) => onFilters('age_category', e)}
                            label={'Age'}
                            options={[...data.form1[3].option, { label: 'Any', value: null }]}
                        />
                    </Grid>

                    <Grid mb={2}>
                        <Appbutton loading={loading} onClick={handleFilterDetails} boxmt={0} variant="contained">
                            View Result
                        </Appbutton>
                    </Grid>
                </Grid>
                {type == 'Overall Performance' ? <Overall list={overallList} /> : <Details detailsList={detailsList} />}
            </Box>
        </>
    );
}
