import React from 'react';
import { Box } from '@mui/material';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const labels = ['Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q12'];

export const dumbData = {
    labels,
    datasets: [
        {
            label: 'Insights: Beliefs',
            data: Array.from({ length: 12 }, () => Math.floor(Math.random() * 100) + 1),
            backgroundColor: '#e0afa0',
            barThickness: 10
        }
    ]
};

export default function BarChart({
    data,
    custom,
    isPercent,
    customeToltip,
    fontSize,
    height,
    displayLegend = true,
    indexAxis = 'x',
    max = 100
}) {
    const options = {
        indexAxis: indexAxis,
        aspectRatio: 5,
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            legend: {
                position: 'bottom',
                display: displayLegend,
                labels: {
                    font: 8
                }
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
                font: {
                    size: '1.5rem'
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context, i, s) {
                        var label = customeToltip ? context.dataset.customeLabels[context.dataIndex] || '' : context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.parsed[indexAxis == 'x' ? 'y' : 'x'];
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: fontSize // set font size here
                    }
                }
            },
            y: {
                min: 0,
                max: max,
                ticks: isPercent && {
                    beginAtZero: true,
                    stepSize: 20 /* total/4 shows 0, 25%, 50%, 75%, 100% */,
                    callback: function (value, index, values) {
                        if (custom) {
                            return data.labels[value];
                        } else {
                            return value + '%';
                        }
                    }
                }
            }
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Bar width={'100%'} height={height} options={options} data={data ? data : dumbData} />
        </Box>
    );
}
