import React from 'react';
import { Grid, Typography } from '@mui/material';
import BarChart from 'ui-component/Charts/Bar';
import { useSelector } from 'react-redux';
export const dumbData = {
    labels: ['Avrage', 'Insight 1', 'Insight 2', 'Insight 3'],
    datasets: [
        {
            label: 'Insights: Beliefs',
            data: Array.from({ length: 4 }, () => Math.floor(Math.random() * 100) + 1),
            backgroundColor: '#e0afa0'
        }
    ]
};
export default function Overall({ list }) {
    const borderRadius = useSelector((state) => state.customization.borderRadius);

    return (
        <Grid container justifyContent={'space-between'}>
            {/* {list.length >= 1 &&
                list.map((v, i) => {
                    return ( */}
            {list.length >= 1 ? (
                <>
                    <Grid
                        // key={i}
                        item
                        xs={12}
                        sm={5.9}
                        sx={{
                            borderRadius: `${borderRadius}px`,
                            background: 'white',
                            p: 2
                        }}
                    >
                        <Typography variant="h4" textAlign={'center'}>
                            {list[0]?.title}
                        </Typography>
                        <Grid>
                            {list.length >= 1 && (
                                <BarChart
                                    custom
                                    isPercent
                                    displayLegend={false}
                                    height={'300px'}
                                    indexAxis="y"
                                    data={{
                                        labels: list[0].labels,
                                        datasets: list[0].dataset
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        // key={i}
                        item
                        xs={12}
                        sm={5.9}
                        sx={{
                            borderRadius: `${borderRadius}px`,
                            background: 'white',
                            p: 2
                        }}
                    >
                        <Typography variant="h4" textAlign={'center'}>
                            {list[1]?.title}
                        </Typography>
                        <Grid>
                            {list.length >= 1 && (
                                <BarChart
                                    custom
                                    isPercent
                                    displayLegend={false}
                                    height={'300px'}
                                    indexAxis="y"
                                    data={{
                                        labels: list[1].labels,
                                        datasets: list[1].dataset
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid mt={2} display={'flex'} justifyContent="center" xs={12} item>
                    <Typography variant="h5">Data not found</Typography>
                </Grid>
            )}
            {/* );
                })} */}
        </Grid>
    );
}
