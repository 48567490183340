import { Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useId } from 'react';
import ForwardIcon from '@mui/icons-material/Forward';

const Steps = ({ children, steps }) => {
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.down('md'));
    const [readMore, setReadMore] = useState(false);
    const arrowId = useId();

    const handleReadMore = () => {
        setReadMore((prev) => !prev);
    };
    return (
        <>
            <Grid
                sx={{
                    marginTop: '3rem',
                    display: 'flex',
                    flexDirection: match ? 'column' : 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    alignItems: match ? 'center' : 'none',
                    paddingX: match ? '2rem' : 'none',
                    gap: '1rem',
                    width: '100%'
                }}
            >
                {readMore
                    ? children
                    : steps?.map((step, idx) => (
                          <Box
                              key={step.key}
                              sx={{
                                  width: match ? '100%' : 'auto',
                                  display: 'flex',
                                  flexDirection: match ? 'column' : 'row',
                                  alignItems: match ? 'center' : 'none',
                                  flexGrow: 1,
                                  //   flexShrink: 1
                                  flexBasis: match ? 'auto' : '13rem'
                              }}
                          >
                              {idx !== 0 && (
                                  <ForwardIcon
                                      fontSize="large"
                                      sx={{ alignSelf: 'center', color: '#00706b', rotate: match ? '90deg' : '0deg', marginRight: '1rem' }}
                                  />
                              )}
                              <Grid
                                  sx={{
                                      backgroundColor: 'white',
                                      padding: '0.7rem',
                                      flexGrow: 1,
                                      width: match ? '100%' : 'auto',
                                      borderRadius: '20px',
                                      boxShadow: '2px 2px 10px 1px #9e9e9e'
                                  }}
                              >
                                  <Typography variant="h3">{step.title}</Typography>
                                  <Box
                                      sx={{
                                          paddingLeft: '.5rem',
                                          paddingTop: '.5rem',
                                          fontSize: '1rem',
                                          color: '#212121'
                                      }}
                                  >
                                      {step.value()}
                                  </Box>
                              </Grid>
                          </Box>
                      ))}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'end'
                }}
            >
                <Button
                    onClick={handleReadMore}
                    sx={{
                        borderRadius: '50px',
                        marginTop: '2rem',
                        padding: '5px',
                        border: '1px solid #00706b',
                        color: 'white',
                        backgroundColor: '#00706b',
                        cursor: 'pointer',
                        ':hover': {
                            color: 'white',
                            backgroundColor: '#00706b'
                        },
                        textTransform: 'lowercase'
                    }}
                >
                    {readMore ? 'steps...' : 'text...'}
                </Button>
            </Grid>
        </>
    );
};

export default Steps;
