// assets
import { IconUser } from '@tabler/icons';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
// constant
const icons = {
    IconUser
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'myAccount',
    title: 'Account Settings',
    type: 'group',
    caption: 'Manage Your Account and Take Control of Your Assessments',
    children: [
        {
            id: 'my-accounty',
            title: 'My Account',
            type: 'item',
            url: '/account',
            icon: icons.IconUser,
            breadcrumbs: true
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: '/login',
            icon: LogoutOutlinedIcon,
            breadcrumbs: true
        }
    ]
};

export default utilities;
