// assets
import { IconLayout, IconHome } from '@tabler/icons';

// constant
const icons = { IconLayout, IconHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'My Dashboard',
    type: 'group',
    caption: 'Stay Informed and Track Your Progress!',
    children: [
        // {
        //     id: 'home',
        //     title: 'Home',
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.IconHome,
        //     breadcrumbs: true
        // },
        {
            id: 'default',
            title: 'Overview',
            type: 'item',
            url: '/overview',
            icon: icons.IconLayout,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
