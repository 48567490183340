import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Typography, alpha, Box } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
    labels: ['Leadership Character', 'Beliefs', 'Empty'],
    datasets: [
        {
            label: 'Insights: Leadership Development',
            data: [100, 180, 80],
            backgroundColor: [alpha('#e0afa0', 0.5), alpha('#bcb8b1', 0.5), 'rgba(0, 0, 0, 0)'],
            borderColor: ['#e0afa0', '#bcb8b1', 'rgba(200, 200, 200, 0.5)'],
            borderWidth: 1
        }
    ]
};

const data2 = {
    labels: ['Leadership Character', 'Beliefs', 'Empty'],
    datasets: [
        {
            label: 'Insights: Leadership Development',
            data: [100, 180, 80],
            backgroundColor: [alpha('#669bbc', 0.5), alpha('#4a4e69', 0.5), 'rgba(0, 0, 0, 0)'],
            borderColor: ['#669bbc', '#4a4e69', 'rgba(200, 200, 200, 0.5)'],
            borderWidth: 1
        }
    ]
};

const data3 = {
    labels: ['Leadership Character', 'Beliefs', 'Empty'],
    datasets: [
        {
            label: 'Insights: Leadership Development',
            data: [100, 180, 80],
            backgroundColor: [alpha('#aaa1c8', 0.5), alpha('#4a4e69', 0.5), 'rgba(0, 0, 0, 0)'],
            borderColor: ['#aaa1c8', '#4a4e69', 'rgba(200, 200, 200, 0.5)'],
            borderWidth: 1
        }
    ]
};

const options = {
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
            text: 'Doughnut Chart',
            font: {
                size: '1.5rem'
            }
        }
    }
};

export default function App(props) {
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: '',
                data: [],
                backgroundColor: [],
                borderColor: [],
                borderWidth: 0
            }
        ]
    });

    useEffect(() => {
        const score = props.data && props.data.chartData?.map((v) => v.score);
        const lab = props.data && props.data.chartData?.map((v) => v.theme_title);

        setData({
            labels: [...lab, 'Area for improvement'],
            datasets: [
                {
                    label: [props.data.product],
                    data: props.data && [...score, props.data.empty],
                    backgroundColor: [alpha(props.color1, 1.8), alpha(props.color2, 1.8), 'rgba(0, 0, 0, 0)'],
                    borderColor: [alpha(props.color1, 1.9), alpha(props.color2, 1.9), 'rgba(200, 200, 200, 1.2)'],
                    borderWidth: 1,
                    circumference: props.isHalf ? 180 : 360,
                    rotation: props.isHalf ? 270 : 360
                }
            ]
        });
    }, [props.data]);

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {data.datasets[0].data.length >= 1 && <Doughnut height={props.chartHeight} data={data} options={options} />}
            <Typography variant="h5" style={{ textAlign: 'center' }} sx={{ mt: 1 }}>
                {props.title}
            </Typography>
        </Box>
    );
}
