import PropTypes from 'prop-types';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    linearProgressClasses
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

// assets
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import { useEffect, useState } from 'react';
import api from 'config/api';

const DONE_VALUE = 2;
const NUMBER_ASSESSMENTS = 10;
const PERCENTAGE = Math.round((DONE_VALUE / NUMBER_ASSESSMENTS) * 100);

// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 30,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#fff'
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.secondary[1100]
    }
}));

const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.secondary[1000],
    marginBottom: '22px',
    overflow: 'hidden',
    position: 'relative'
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
    const theme = useTheme();

    return (
        <Grid container direction="column" spacing={1} sx={{ mb: 2 }}>
            <Grid item>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6" sx={{ color: theme.palette.primary[800] }}>
                            Progress
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" color={'inherit'}>{`${Math.round(value)}%`}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BorderLinearProgress variant="determinate" value={value} {...others} />
            </Grid>
        </Grid>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};

// ==============================|| Motivational Texts ||============================== //

const getPercentageText = (percentage) => {
    if (percentage <= 0) {
        return 'Start your Journey now!';
    } else if (percentage > 0 && percentage <= 25) {
        return "You've made a great start! Keep pushing forward and build on that progress. Stay determined, and you'll achieve success!";
    } else if (percentage > 25 && percentage <= 50) {
        return "You're almost halfway there! Keep up the good work, and you'll be done before you know it. Don't give up now!";
    } else if (percentage > 50 && percentage <= 75) {
        return "You're doing a fantastic job! The finish line is in sight. Stay motivated, and you'll see the results coming!";
    } else {
        return "Amazing work! You've come so far with only few assessments left. Stay focused, and remember, the finish line is just around the corner!";
    }
};

// ==============================|| SIDEBAR MENU Card ||============================== //

const MenuCard = () => {
    // const
    const user_detail = useSelector((state) => state.customization.user_detail);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        attempts: 0,
        dones: 0,
        percentage: 0
    });
    const theme = useTheme();
    useEffect(() => {
        api.progressAssessment()
            .then((res) => {
                setState(res.data.result);
                const isCheckDone = res.data.result.dones !== 0;
                dispatch({ type: 'IS_TEST', isCheckDone });
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            {!user_detail.company_account && (
                <CardStyle sx={{ mt: 3, mb: 3, background: '#E1EBF2' }}>
                    <CardContent sx={{ p: 2 }}>
                        <List sx={{ p: 0, m: 0 }}>
                            <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                                <ListItemText
                                    sx={{ mt: 0 }}
                                    primary={
                                        <Typography variant="subtitle1" sx={{ color: theme.palette.primary[800] }}>
                                            Assessment Progress
                                        </Typography>
                                    }
                                    secondary={
                                        <>
                                            <Typography variant="caption" sx={{ color: theme.palette.text.primary }}>
                                                {state.dones} out of {state.attempts} Attempts completed
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                        </List>

                        <LinearProgressWithLabel value={state.percentage} />

                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {getPercentageText(state.dones)}
                        </Typography>
                    </CardContent>
                </CardStyle>
            )}
        </>
    );
};

export default MenuCard;
