// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container_according__55wtv {
  margin: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layout/MainLayout/Sidebar/MenuList/NavGroup/style.module.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,WAAA;AACJ","sourcesContent":[".container_according {\n    margin: 0;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_according": `style_container_according__55wtv`
};
export default ___CSS_LOADER_EXPORT___;
