import { Grid, Typography } from '@mui/material';
import React from 'react';
import style from '../style.module.scss';

import { useState } from 'react';

export default function SwitchContent({ onActiveContent, firstContent = 'Basic', secendContent = 'Premium' }) {
    const [state, setState] = useState('');
    const onClick = (type) => {
        setState(type);
        onActiveContent(type);
    };

    return (
        <Grid className={style.container_switch}>
            <Grid height={'100%'} onClick={() => onClick(firstContent)} className={style.render_button}>
                <Typography variant="h5">{firstContent}</Typography>
            </Grid>
            <Grid height={'100%'} onClick={() => onClick(secendContent)} className={style.render_button}>
                <Typography variant="h5">{secendContent}</Typography>
            </Grid>
            <Grid className={style.container_active}>
                <Grid className={`${style.active_set} ${state == secendContent ? style.active_right : style.active_left}`} />
            </Grid>
        </Grid>
    );
}
