// assets
import { IconLayout, IconHome } from '@tabler/icons';
import ChatIcon from '@mui/icons-material/Chat';
// constant
const icons = { IconLayout, IconHome };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const submitquery = {
    id: 'Submit Query',
    title: 'Submit Query',
    type: 'group',
    // caption: 'Stay Informed and Track Your Progress!',
    children: [
        {
            id: 'ask a question?',
            title: 'Ask a question?',
            type: 'item',
            url: '/ask_question',
            icon: ChatIcon,
            breadcrumbs: true
        }
    ]
};

export default submitquery;
