import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

import PrivateRoute from './PrivateRoutes';
import EmployedPerformance from 'views/employedPerformance';
// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard/index')));
const Overview = Loadable(lazy(() => import('views/overview/index')));
const Assessments = Loadable(lazy(() => import('views/assessment/index')));
const DoneAssessments = Loadable(lazy(() => import('views/done_assessments')));
const Reports = Loadable(lazy(() => import('views/reports/index')));
const UserAccount = Loadable(lazy(() => import('views/user-account/index')));
const MangeMember = Loadable(lazy(() => import('views/employedManage')));
const CompanyDetail = Loadable(lazy(() => import('views/companyDetail')));
const UserCompanyreport = Loadable(lazy(() => import('views/usercompanyReport')));
const Ask_question = Loadable(lazy(() => import('views/Aks_question')));
const FeedBack = Loadable(lazy(() => import('views/feedback')));
const Upgrade = Loadable(lazy(() => import('views/upgrade')));
// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            )
        },
        {
            path: '/dashboard',
            element: (
                <PrivateRoute>
                    <Dashboard />
                </PrivateRoute>
            )
        },
        {
            path: '/overview',
            element: (
                <PrivateRoute>
                    <Overview />
                </PrivateRoute>
            )
        },
        {
            path: 'assessments',
            children: [
                {
                    path: '',
                    element: (
                        <PrivateRoute>
                            <Assessments />
                        </PrivateRoute>
                    )
                }
            ]
        },
        {
            path: 'done_assessments',
            children: [
                {
                    path: '',
                    element: (
                        <PrivateRoute>
                            <DoneAssessments />
                        </PrivateRoute>
                    )
                }
            ]
        },
        {
            path: '/reports',
            element: (
                <PrivateRoute>
                    <Reports />
                </PrivateRoute>
            )
        },
        {
            path: '/account',
            element: (
                <PrivateRoute>
                    <UserAccount />
                </PrivateRoute>
            )
        },
        {
            path: '/managment',
            element: (
                <PrivateRoute>
                    <MangeMember />
                </PrivateRoute>
            )
        },
        {
            path: '/performance',
            element: (
                <PrivateRoute>
                    <EmployedPerformance />
                </PrivateRoute>
            )
        },
        {
            path: '/ask_question',
            element: (
                <PrivateRoute>
                    <Ask_question />
                </PrivateRoute>
            )
        },
        {
            path: '/feedback',
            element: (
                <PrivateRoute>
                    <FeedBack />
                </PrivateRoute>
            )
        },
        {
            path: '/report/:role/:name/:id',
            element: (
                <PrivateRoute>
                    <UserCompanyreport />
                </PrivateRoute>
            )
        }
        // {
        //     path: '/upgrade',
        //     element: (
        //         <PrivateRoute>
        //             <Upgrade />
        //         </PrivateRoute>
        //     )
        // }
    ]
};

export default MainRoutes;
