import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/authentication/authentication3/Register3')));
const ForgotPassword3 = Loadable(lazy(() => import('views/authentication/authentication3/ForgotPassword3')));
const DirectPurchase = Loadable(lazy(() => import('views/direct-purchase')));
const Terms = Loadable(lazy(() => import('views/Terms')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword3 />
        },
        {
            path: '/terms',
            element: <Terms />
        },
        {
            path: '/direct-purchase',
            element: <DirectPurchase />
        }
    ]
};

export default AuthenticationRoutes;
