import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

import AppAccording from 'common/AppAccording';
import styles from './style.module.scss';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <AppAccording
                classNameContainer={styles.container_according}
                data={[
                    {
                        title: (
                            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                {item?.title}
                                {item?.caption && (
                                    <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                        {item.caption}
                                    </Typography>
                                )}
                            </Typography>
                        ),

                        content: items
                    }
                ]}
                background={theme.palette.background.menuColor}
                accordionDetailsBg={theme.palette.background.menuColor}
                aroundContentBg={theme.palette.background.menuColor}
                defaultExpandedPanel={item.title === 'My Dashboard' ? 'panel0' : false}
                arrowIconStyles={{
                    color: 'white',
                    transform: 'rotate(90deg)',
                    marginTop: '5px'
                }}
                flexDirectonOfArrowAndTitle="row"
                heightOfSummary="65px"
            />

            {/* group divider */}
            <Divider />
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
