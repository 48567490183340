import { Grid } from '@mui/material';
import React from 'react';
import AppTab from 'common/AppTab';
import Numeric from './Numeric';
import Graphically from './Graphically';
export default function EmployedPerformance({ packageSelected }) {
    const tab = [
        {
            title: 'Numerically',
            value: '1',
            content: <Numeric packageSelected={packageSelected} />
        },
        {
            title: 'Graphically',
            value: '2',
            content: <Graphically packageSelected={packageSelected} />
        }
    ];
    return (
        <Grid mt={2}>
            <AppTab tabs={tab} />
        </Grid>
    );
}
