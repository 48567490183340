import dashboard from 'menu-items/indvisual/dashboard';
import userSpace from 'menu-items/indvisual/userSpace';
import myAccount from 'menu-items/indvisual/myAccount';
import assessment from 'menu-items/indvisual/assessment';
import company from './company';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, assessment, company, userSpace, myAccount]
};

export default menuItems;
