import dashboard from './dashboard';
import company from './company';
import myAccount from './myAccount';
import purchase from 'menu-items/indvisual/purchase';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, purchase, company, myAccount]
};

export default menuItems;
