// assets
import { IconCheckbox, IconReportAnalytics } from '@tabler/icons';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
// constant
const icons = {
    IconCheckbox,
    IconReportAnalytics
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'my Results',
    title: 'My Results',
    // caption: 'Access Your Results and Analyze Your Performance',
    type: 'group',
    children: [
        {
            id: 'View My Result',
            title: 'View My Result',
            type: 'item',
            url: '/reports',
            icon: icons.IconReportAnalytics,
            breadcrumbs: true
        },
        {
            id: 'default',
            title: 'Summary of All Results',
            type: 'item',
            url: '/overview',
            icon: AssessmentOutlinedIcon,
            breadcrumbs: true
        }
    ]
};

export default pages;
