// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';
import { voucherId } from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: false,
    Authed: null,
    user_detail: {},
    is_test: false,
    country: '',
    currency: '',
    voucherId: null
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;

    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SET_AUTHED:
            return {
                ...state,
                Authed: action.Authed
            };
        case 'USER_DETAIL':
            return {
                ...state,
                user_detail: action.user_detail
            };
        case 'IS_TEST':
            return {
                ...state,
                is_test: action.isCheckDone
            };
        case 'COUNTRY':
            return {
                ...state,
                country: action.country
            };
        case 'CURRENCY':
            return {
                ...state,
                currency: action.res_currency
            };
        case 'VoucherId':
            return {
                ...state,
                voucherId: action.voucherId
            };

        default:
            return state;
    }
};

export default customizationReducer;
