import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SwitchContent from 'components/OverView/Packages/SwitchContent';
import AppTable from 'common/AppTable';
import api from 'config/api';
import AppSelect from 'common/AppSelect';

export default function Numeric({ packageSelected }) {
    const [tableTitle, setTableTitle] = useState([]);
    const [themeSelect, setThemeSelect] = useState(null);
    const [list, setList] = useState([]);
    const [typePerformance, setTypePerformance] = useState('Overall Performance');

    const onPerformance = (type) => {
        setTypePerformance(type);
        type == 'Overall Performance'
            ? getListOverall(packageSelected.id)
            : getList(packageSelected.themes[0].value, packageSelected.id, packageSelected.themes[0].label);
    };

    const getList = (themeID, packID, themeLabel) => {
        api.getListPerformance({
            subscriptionId: packID,
            themeId: themeID
        })
            .then((res) => {
                setList(() =>
                    res.data.map((v, i) => {
                        return {
                            first_name: v.first_name,
                            last_name: v.last_name,
                            departman: v.departman,
                            position: v.position,
                            gender: v.gender,
                            age_group: v.age_group,
                            attempts: v.attempts,
                            overall_Package_Average: v.overall_Package_Average,
                            [`${Object.keys(v).find((item) => item.includes('Overall ThemeScore'))}`]:
                                v[`${Object.keys(v).find((item) => item.includes('Overall ThemeScore'))}`]
                        };
                    })
                );
                setTableTitle([
                    'First name',
                    'Last name',
                    'Departman',
                    'Position',
                    'Gender',
                    'Age group',
                    'Attempt',
                    `Overall ${packageSelected.name} Average`,
                    `Overall ${themeLabel}`
                ]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getListOverall = (packID) => {
        api.getOverallListNumeric(packID)
            .then((res) => {
                console.log(res);
                setList(() =>
                    res.data.map((item, index) => {
                        return {
                            [`Average ${packageSelected.name}`]: item.Package_Average,
                            theme: item.theme_title,
                            [`${item.insights[0].insight_title}`]: item.insights[0].score,
                            [`${item.insights[1].insight_title}`]: item.insights[1].score,
                            [`${item.insights[2].insight_title}`]: item.insights[2].score
                        };
                    })
                );
                setTableTitle([
                    `Average ${packageSelected.name}`,
                    'Theme',
                    `${res.data[0].insights[0].insight_title}`,
                    `${res.data[0].insights[1].insight_title}`,
                    `${res.data[0].insights[2].insight_title}`
                ]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onchangeThemes = (e, i) => {
        const { value } = e.target;

        getList(value, packageSelected.id, i.props.name);
        setThemeSelect({ value: value, name: i.props.name });
    };

    useEffect(() => {
        getListOverall(packageSelected.id);
    }, []);

    useEffect(() => {
        typePerformance == 'Performance Details' &&
            setTableTitle([
                'First name',
                'Last name',
                'Departman',
                'Position',
                'Gender',
                'Age group',
                'Attempt',
                `Overall ${packageSelected.name} Average`,
                `Overall ${packageSelected.themes[0].label} ThemeScore`
            ]);
    }, [packageSelected]);

    return (
        <Grid>
            <Grid sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
                <Grid sx={{ height: '40px', width: '400px' }}>
                    <SwitchContent firstContent="Overall Performance" secendContent="Performance Details" onActiveContent={onPerformance} />
                </Grid>
            </Grid>
            {typePerformance == 'Performance Details' && (
                <Grid container>
                    <Grid width={'300px'}>
                        <AppSelect
                            value={themeSelect ? themeSelect.value : packageSelected.themes[0].value}
                            label={'Themes'}
                            options={packageSelected.themes}
                            handleChange={onchangeThemes}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid sx={{ marginTop: '32px' }}>
                <AppTable
                    lables={tableTitle}
                    rows={list}
                    staticHeader={['Employees', 'Personal Characteristics']}
                    staticKeys={['name', 'personal']}
                    // isStatic
                />
            </Grid>
        </Grid>
    );
}
