// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_AUTHED = '@customization/SET_AUTHED';
export const USER_DETAIL = '@customization/USER_DETAIL';
export const IS_TEST = '@customization/IS_TEST';
export const COUNTRY = '@customization/COUNTRY';
export const CURRENCY = '@customization/CURRENCY';
export const VoucherId = '@customization/VoucherId';
