import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Confirm from 'common/AppModal/Confirm';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';
import Cookies from 'js-cookie';
// project imports
import Logout from 'assets/images/authLayout/logout.png';
import { MENU_OPEN, SET_MENU, SET_AUTHED } from 'store/actions';
// assets

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNavigate } from 'react-router';
import Appbutton from 'common/Appbutton';
// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
    const [openModal, setOpenModal] = useState(false);
    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    const itemHandler = (item, id) => {
        if (item.id == 'logout') {
            setOpenModal(true);
        } else {
            navigate(item.url);
            dispatch({ type: MENU_OPEN, id });
            if (matchesSM) dispatch({ type: SET_MENU, opened: false });
        }
    };
    const handleLogout = () => {
        const ss = false;
        Cookies.remove('usertoken');
        dispatch({ type: SET_AUTHED, ss });
    };
    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Confirm isOpen={openModal} contentConfirm={'Yes'} contentCancel={'No'} noneButton modalWidth="40%">
                <Grid sx={{ height: '200px' }} mb={3}>
                    <img src={Logout} alt="/" style={{ width: '100%', height: '100%', display: 'block', borderRadius: '12px' }} />
                </Grid>
                <Typography variant="h3" mb={3} textAlign={'center'}>
                    Log Out
                </Typography>
                <Typography textAlign={'center'} variant="h5">
                    Are you certain you want to log out?
                </Typography>
                <Grid
                    mt={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    <Appbutton onClick={() => setOpenModal(false)} sx={{ mr: 2 }} variant="outlined">
                        Keep me logged in
                    </Appbutton>
                    <Appbutton onClick={handleLogout} variant="contained">
                        Log Out
                    </Appbutton>
                </Grid>
            </Confirm>
            <ListItemButton
                disabled={item.disabled}
                sx={{
                    borderRadius: `${customization.borderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: document.location.pathname == item.url ? 'rgba(96, 215, 207, 0.4);' : 'inherit',
                    py: document.location.pathname !== item.url ? 1 : 1.25,
                    pl: `${level * 24}px`,
                    color: theme.palette.secondary.light
                }}
                selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                onClick={() => itemHandler(item, item.id)}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36, color: theme.palette.secondary.light }}>
                    {itemIcon}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                            {item.title}
                        </Typography>
                    }
                    secondary={
                        item.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {item.caption}
                            </Typography>
                        )
                    }
                />
            </ListItemButton>
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
