import dashboard from './dashboard';
import userSpace from './userSpace';
import myAccount from './myAccount';
import purchase from './purchase';
import assessment from './assessment';
import submitquery from './submitquery';
import myreports from './myreports';
import feedback from './OfferFeedback';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, purchase, assessment, userSpace, myreports, submitquery, feedback, myAccount]
};

export default menuItems;
