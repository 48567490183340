import PropTypes from 'prop-types';
import React, { useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import Appbutton from 'common/Appbutton';
// third-party

import { BrowserView, MobileView } from 'react-device-detect';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch } from 'react-redux';
// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import config from '../../../config/global';
import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Logout from 'assets/images/authLayout/logout.png';
import Confirm from 'common/AppModal/Confirm';
import { SET_AUTHED } from 'store/actions';
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const handleLogout = async () => {
        const ss = false;
        config.udata = null;
        Cookies.remove('usertoken');

        Auth.signOut().then(() => {
            Cookies.remove('loginSuccess');
            Cookies.remove('registerSuccess');

            localStorage.removeItem('loginSuccess');
            localStorage.removeItem('registerSuccess');
            navigate('/login');
        });
        dispatch({ type: SET_AUTHED, ss });
    };
    const handleModalLogout = () => {
        setOpenModal(true);
    };

    const customization = useSelector((state) => state.customization);
    const drawer = (
        <>
            <Confirm isOpen={openModal} contentConfirm={'Yes'} contentCancel={'No'} noneButton modalWidth="40%">
                <Grid sx={{ height: '200px' }} mb={3}>
                    <img src={Logout} alt="/" style={{ width: '100%', height: '100%', display: 'block', borderRadius: '12px' }} />
                </Grid>
                <Typography variant="h3" mb={3} textAlign={'center'}>
                    Log Out
                </Typography>
                <Typography textAlign={'center'} variant="h5">
                    Are you certain you want to log out?
                </Typography>
                <Grid
                    mt={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    <Appbutton onClick={() => setOpenModal(false)} sx={{ mr: 2 }} variant="outlined">
                        Keep me logged in
                    </Appbutton>
                    <Appbutton onClick={handleLogout} variant="contained">
                        Log Out
                    </Appbutton>
                </Grid>
            </Confirm>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection light={!matchUpMd} />
                </Box>
            </Box>
            <BrowserView>
                <Grid
                    component="div"
                    style={{
                        height: !matchUpMd ? '100%' : '100%',
                        paddingLeft: '16px',
                        paddingRight: '16px',

                        borderRadius: `${customization.borderRadius}px`,
                        marginTop: 0,
                        marginLeft: 6,
                        marginRight: 6,
                        marginBottom: 6,
                        backgroundColor: theme.palette.background.menuColor
                    }}
                >
                    <Link
                        // target="_blank"
                        href="https://ileading360.com"
                        display={'flex'}
                        alignItems={'center'}
                        mt={2}
                        sx={{ cursor: 'pointer', textDecoration: 'none' }}
                    >
                        <ArrowBackIosIcon sx={{ mr: 2, color: theme.palette.grey[300], fontSize: '18px' }} />
                        <Typography variant="h5" sx={{ color: theme.palette.grey[300] }}>
                            Back to website
                        </Typography>
                    </Link>
                    <MenuList />
                    <Grid onClick={handleModalLogout} display={'flex'} alignItems={'center'} mt={2} sx={{ cursor: 'pointer' }}>
                        <LogoutOutlinedIcon sx={{ mr: 2, color: theme.palette.grey[300] }} />
                        <Typography variant="h5" sx={{ color: theme.palette.grey[300] }}>
                            Logout
                        </Typography>
                    </Grid>
                    <MenuCard />
                    <Grid flex flexDirection={'row'}>
                        <Typography
                            sx={{
                                color: 'white',
                                paddingBottom: 2,
                                fontSize: '12px'
                            }}
                        >
                            {'Designed and Developed by '}
                        </Typography>
                        <Link href={'http://thecyberiatech.com'} target="blank">
                            <Typography
                                sx={{
                                    color: 'white',
                                    paddingBottom: 2,
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    display: 'inline-block',
                                    cursor: 'pointer'
                                }}
                            >
                                {'The Cyberia Tech'}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <Link
                        // target="_blank"
                        href="https://ileading360.com"
                        display={'flex'}
                        alignItems={'center'}
                        mt={2}
                        sx={{ cursor: 'pointer', textDecoration: 'none' }}
                    >
                        <ArrowBackIosIcon sx={{ mr: 2, color: theme.palette.grey[300], fontSize: '18px' }} />
                        <Typography variant="h5" sx={{ color: theme.palette.grey[300] }}>
                            Back to website
                        </Typography>
                    </Link>
                    <MenuList />
                    <Grid onClick={handleModalLogout} display={'flex'} alignItems={'center'} mt={2} sx={{ cursor: 'pointer' }}>
                        <LogoutOutlinedIcon sx={{ mr: 2, color: theme.palette.grey[300] }} />
                        <Typography variant="h5" sx={{ color: theme.palette.grey[300] }}>
                            Logout
                        </Typography>
                    </Grid>
                    <MenuCard />
                </Box>
            </MobileView>
        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <>
            <Box
                component="nav"
                sx={{ flexShrink: { md: 0 }, width: matchUpMd ? (drawerOpen ? drawerWidth : '0') : '0' }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant={matchUpMd ? 'persistent' : 'temporary'}
                    anchor="left"
                    open={drawerOpen}
                    onClose={drawerToggle}
                    sx={{
                        '& .MuiDrawer-paper': {
                            borderRadius: `${customization.borderRadius}px`,

                            height: matchUpMd ? `calc(100vh - 102px)` : `calc(100vh - 32px)`,
                            my: matchUpMd ? 0 : 2,
                            mx: 2,

                            width: drawerWidth,
                            background: theme.palette.background.menuColor,
                            color: theme.palette.text.primary,
                            borderRight: 'none',
                            [theme.breakpoints.up('md')]: {
                                top: '88px'
                            },
                            [theme.breakpoints.down('md')]: {
                                visibility: !drawerOpen ? 'hidden' : 'visible'
                            }
                        }
                    }}
                    ModalProps={{ keepMounted: true }}
                    color="inherit"
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
