import React from 'react';

// MUI
import { Modal, Box, Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';

// Style
import style from './style.module.scss';

// Button Global
import Appbutton from '../Appbutton';

// Icon
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({
    myModal: {
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(255,255,255,.3)',
            backdropFilter: 'blur(10px)'
        }
    }
});

export default function AppModal({
    isOpen,
    handleClose,
    children,
    classContent,
    contentConfirm,
    contentCancel,
    handleConfirm,
    type,
    modalWidth = '60%',
    noneButton,
    modalHeight,
    loading,
    haveClose,
    isfull,
    buttonCenter
}) {
    const classes = useStyles();
    return (
        <Modal
            className={classes.myModal}
            open={isOpen}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 700 // Increase this value for a longer, smoother transition
            }}
        >
            <Fade in={isOpen} timeout={1000}>
                <Box
                    sx={{
                        width: isfull ? '100%' : { xs: '90%', sm: '80%', md: modalWidth },
                        minHeight: modalHeight,
                        left: { xs: '47%', sm: '50%' }
                    }}
                    className={style.box_modal}
                >
                    <Grid position={'relative'}>
                        {haveClose && (
                            <Grid
                                onClick={handleClose}
                                sx={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '-22px',
                                    right: '-20px'
                                }}
                            >
                                <CloseIcon />
                            </Grid>
                        )}
                        <Grid className={classContent} mr={2} id="modal-modal-description">
                            {children}
                        </Grid>
                        {!noneButton && (
                            <Grid
                                container
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: buttonCenter ? 'center' : 'flex-end',
                                    marginTop: '50px'
                                }}
                            >
                                {contentCancel && (
                                    <Appbutton sx={{ mr: 1 }} variant="outlined" onClick={handleClose}>
                                        {contentCancel}
                                    </Appbutton>
                                )}

                                <Appbutton loading={loading} variant="contained" type={type} onClick={handleConfirm}>
                                    {contentConfirm}
                                </Appbutton>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
}
